import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import useWebSocket from 'react-use-websocket';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, AppBar, Toolbar, Typography, Container, Box, List, ListItem, ListItemText, Divider, Paper } from '@mui/material';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Configuration de  l'icône Leaftlet
const customIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const App = () => {

  //Déclaration des variables d'état
  const [username, setUsername] = useState('');
  const [position, setPosition] = useState(null);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(true);
  const mapRef = useRef(null);
  const [acceleration, setAcceleration] = useState({ x: 0, y: 0, z: 0 });
  const [isIOS, setIsIOS] = useState(false);

  // Hook WebSocket pour gérer la connexion
  const { sendMessage, lastMessage, getWebSocket } = useWebSocket('wss://dimitri.perriet.caen.mds-project.fr:8080/ws', {
    onOpen: () => console.log('Connexion WebSocket établie'),
    onClose: () => console.log('Connexion WebSocket fermée'),
    onError: (error) => console.error('Erreur WebSocket :', error),
    shouldReconnect: (closeEvent) => true,
  });

  // Gérer l'événement de mouvement de l'appareil
  const handleDeviceMotion = (event) => {
    const acceleration = event.accelerationIncludingGravity;
    const x = parseFloat(acceleration.x.toFixed(2));
    const y = parseFloat(acceleration.y.toFixed(2));
    const z = parseFloat(acceleration.z.toFixed(2));
    setAcceleration({ x, y, z });
  };

  // Demander la permission pour accéder aux données d'accélération sur iOS
  const requestAccelerationPermission = () => {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              window.addEventListener('devicemotion', handleDeviceMotion);
            }
          })
          .catch(console.error);
    }
  };

  // Effet pour définir l'état iOS et ajouter un écouteur d'événement pour le mouvement de l'appareil
  useEffect(() => {
    setIsIOS(navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false);
    if (!isIOS) {
      if (window.DeviceMotionEvent) {
        window.addEventListener('devicemotion', handleDeviceMotion);
      } else {
        console.log("DeviceMotionEvent n'est pas supporté");
      }
    }

    return () => {
      window.removeEventListener('devicemotion', handleDeviceMotion);
    };
  }, [isIOS]);

  // Effet pour gérer les messages WebSocket entrants
  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const data = JSON.parse(lastMessage.data);
        console.log('Données reçues par WebSocket:', data);
        setUsers(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Erreur de parsing JSON :', error);
        console.log('Données reçues :', lastMessage.data);
        setUsers([]);
      }
    }
  }, [lastMessage]);

  // Effet pour obtenir la géolocalisation de l'utilisateur et envoyer les données au serveur
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        setPosition({ latitude, longitude });
      });
    }

    if (username && position) {
      sendData();
    }
  }, [position, acceleration]);

  // Fonction pour envoyer les données de l'utilisateur au serveur
  const sendData = () => {
    if (username && position) {
      sendMessage(JSON.stringify({ type: 'userUpdate', username, location: position, acceleration }));
    }
  };

  // Gérer la soumission du formulaire pour définir le nom d'utilisateur et envoyer les données initiales
  const handleSubmit = async () => {
    if (username && position) {
      const { latitude, longitude } = position;
      await axios.post('https://dimitri.perriet.caen.mds-project.fr:8080/api/users/upsert', {
        username,
        location: { latitude, longitude }
      });
      const socket = getWebSocket();
      socket.username = username;
      sendData();
      setOpen(false);
    }
  };

  return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Application de suivi en temps Réel
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ marginTop: 4 }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>
              <MapContainer
                  center={[46.603354, 1.888334]}
                  zoom={6}
                  style={{ height: '600px', width: '100%', marginBottom: '20px' }}
                  whenCreated={mapInstance => { mapRef.current = mapInstance }}
              >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {position && (
                    <Marker position={[position.latitude, position.longitude]} icon={customIcon}>
                      <Popup>
                        <strong>Ma position</strong>
                      </Popup>
                    </Marker>
                )}
                {Array.isArray(users) && users.map((user, index) => (
                    user.username !== username && (
                        <Marker key={index} position={[user.location.latitude, user.location.longitude]} icon={customIcon}>
                          <Popup>
                            <div>
                              <strong>{user.username}</strong><br />
                              Latitude: {user.location.latitude}, Longitude: {user.location.longitude}
                            </div>
                          </Popup>
                        </Marker>
                    )
                ))}
              </MapContainer>
            </Box>
            <Box sx={{ width: 300, marginLeft: 2 }}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" component="div">
                  Liste des utilisateurs
                </Typography>
                <List>
                  {Array.isArray(users) && users.map((user, index) => (
                      <React.Fragment key={index}>
                        <ListItemText
                            primary={user.username === username ? 'Ma position' : user.username}
                            secondary={
                              <span>
                          Latitude: {user.location.latitude}, Longitude: {user.location.longitude}
                                {user.acceleration && (
                                    <div>
                                      <br />Accélération en X: {user.acceleration.x} m/s²
                                      <br />Accélération en Y: {user.acceleration.y} m/s²
                                      <br />Accélération en Z: {user.acceleration.z} m/s²
                                    </div>
                                )}
                                {isIOS && user.username === username && (
                                    <button onClick={requestAccelerationPermission}>
                                      Activer l'accéléromètre
                                    </button>
                                )}
                        </span>
                            }
                        />
                        <Divider />
                      </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Box>
          </Box>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Choisissez votre nom d'utilisateur</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Veuillez entrer votre nom d'utilisateur pour commencer à utiliser l'application.
              </DialogContentText>
              <TextField
                  autoFocus
                  margin="dense"
                  label="Nom d'utilisateur"
                  type="text"
                  fullWidth
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Annuler</Button>
              <Button onClick={handleSubmit}>Confirmer</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </div>
  );
};

export default App;
